"use client";
import SocialCard, { SocialCardProps } from "@/commonComponents/cards/social";
import {
  MotionBoxProps,
  MotionCardProps,
  MotionGrid,
  MotionGridProps,
  MotionImage,
  MotionStack,
  MotionTypography,
  MotionTypographyProps,
} from "@/commonComponents/motion-components";
import SectionWrapper from "@/commonComponents/section-wrapper";
import { Stack, styled, Theme, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useMemo } from "react";
import ArrowGradient from "@cc/arrow-gradient";
import { H6_2 } from "@/styles/theme/components/typography.fontvariant";
import { motion } from "framer-motion";
import {
  bottomTextStagger,
  iconStagger,
  iconStaggerChildren,
  textStaggerChildren,
} from "@/commonComponents/animations";
import Link from "@cc/link";
import { filter } from "lodash";

const HoverGrid = styled((props: MotionGridProps) => (
  <MotionGrid
    initial={{
      scale: 1,
    }}
    whileHover={{
      scale: 1.1,
      zIndex: 1,
    }}
    {...props}
  />
))({});

export interface SocialSectionProps {
  title?: string;
  description?: string;
  socialHandle?: string;
  gallery: SocialCardProps[];
  links?: {
    icon: string;
    url: string;
    name: string;
    type?: string;
  }[];
}

const SocialSection = (props: SocialSectionProps) => {
  const { title, description, gallery, socialHandle, links = [] } = props;

  const matches = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm_md"),
  );

  const socialLinks = useMemo(() => {
    const size = matches ? 32 : 48;
    const tempLinks = links.map((item, idx) => {
      if (!item.url || item.url === "#") return null;
      return (
        <Link
          key={idx}
          href={item.url}
          title={item.name}
          target={`${item.type}` || "_blank"}
        >
          <MotionImage
            src={item.icon}
            alt={item.name}
            width={size}
            height={size}
            sx={{ width: size, height: size }}
            variants={iconStaggerChildren}
          />
        </Link>
      );
    });
    return filter(tempLinks, (item) => !!item);
  }, [links, matches]);

  return (
    <SectionWrapper
      py={{ xs: 7, md: 12 }}
      SectionProps={{
        bgcolor: "primary.500",
      }}
      color={"common.white"}
    >
      {matches && title && (
        <Stack mb={"38px"} position={"relative"}>
          <MotionTypography
            variant="h1"
            component={"p"}
            width={"min-content"}
            {...textTransition}
          >
            {title}
          </MotionTypography>
          <ArrowGradient
            sx={{
              position: "absolute",
              height: 172,
              right: "-80px",
              bottom: "-68px",
            }}
            {...arrowTransition}
          />
        </Stack>
      )}
      <Grid2
        container
        maxWidth={867}
        m={{ xs: 0, sm_md: "auto" }}
        rowGap={{ xs: 2, sm_md: 0 }}
        columnSpacing={{ xs: 2, sm_md: 0 }}
      >
        <HoverGrid xs={6} sm_md={4}>
          <SocialCard
            {...gallery[0]}
            sx={{
              backgroundPosition: "right top",
              backgroundSize: "270%",
            }}
            {...cardTransitionDown(0)}
          />
        </HoverGrid>
        {!matches && title && (
          <Grid2
            sm_md={8}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
          >
            <MotionTypography
              variant="h1"
              component={"p"}
              width={"min-content"}
              {...textTransition}
            >
              {title}
            </MotionTypography>
            <ArrowGradient
              sx={{
                position: "absolute",
                height: { xs: 300, lg: 480 },
                right: { xs: "-10vw", lg: "-14vw" },
                bottom: { xs: "-50px", lg: "-140px" },
              }}
              {...arrowTransition}
            />
          </Grid2>
        )}
        {!matches && (
          <HoverGrid xs={4} overflow="hidden">
            <SocialCard {...gallery[1]} {...cardTransitionDown(0.5)} />
          </HoverGrid>
        )}
        <HoverGrid xs={6} sm_md={4} overflow="hidden">
          <SocialCard {...gallery[2]} {...cardTransitionRight(1)} />
        </HoverGrid>
        <HoverGrid xs={6} sm_md={4} overflow="hidden">
          <SocialCard
            {...gallery[3]}
            sx={{
              backgroundPosition: "left",
            }}
            {...cardTransitionRight(1.5)}
          />
        </HoverGrid>
        {!matches && (description || socialHandle || links) && (
          <Grid2 sm_md={8}>
            <Stack
              maxWidth={450}
              height={1}
              justifyContent={"center"}
              component={motion.div}
              variants={bottomTextStagger(2.4)}
              initial={"initial"}
              whileInView={"animate"}
              viewport={{ once: true }}
            >
              {(description || socialHandle) && (
                <Stack
                  rowGap={3}
                  component={motion.div}
                  variants={textStaggerChildren}
                >
                  {description && (
                    <MotionTypography fontSize={H6_2}>
                      {description}
                    </MotionTypography>
                  )}
                  {socialHandle && socialLinks.length > 0 && (
                    <MotionTypography fontSize={H6_2} fontWeight={700}>
                      {socialHandle}
                    </MotionTypography>
                  )}
                </Stack>
              )}
              {links && (
                <Stack
                  direction={"row"}
                  gap={4}
                  mt={2}
                  component={motion.div}
                  variants={iconStagger}
                >
                  {socialLinks}
                  {/*{links.map((item, idx) =>*/}
                  {/*  item.url && item.url !== "#" ? (*/}
                  {/*    <Link*/}
                  {/*      key={idx}*/}
                  {/*      href={item.url}*/}
                  {/*      title={item.name}*/}
                  {/*      target={`_${item.type}`}*/}
                  {/*    >*/}
                  {/*      <MotionImage*/}
                  {/*        src={item.icon}*/}
                  {/*        alt={item.name}*/}
                  {/*        width={48}*/}
                  {/*        height={48}*/}
                  {/*        sx={{ width: 48, height: 48 }}*/}
                  {/*        variants={iconStaggerChildren}*/}
                  {/*      />*/}
                  {/*    </Link>*/}
                  {/*  ) : null,*/}
                  {/*)}*/}
                </Stack>
              )}
            </Stack>
          </Grid2>
        )}
        <HoverGrid xs={6} sm_md={4} overflow="hidden">
          <SocialCard {...gallery[4]} {...cardTransitionDown(2)} />
        </HoverGrid>
      </Grid2>
      {matches && (description || socialHandle || links) && (
        <MotionStack
          height={1}
          justifyContent={"center"}
          mt={3}
          variants={bottomTextStagger(2.4)}
          initial={"initial"}
          whileInView={"animate"}
          viewport={{ once: true }}
        >
          {(description || socialHandle) && (
            <MotionStack rowGap={3} variants={textStaggerChildren}>
              {description && (
                <MotionTypography fontSize={H6_2}>
                  {description}
                </MotionTypography>
              )}
              {socialHandle && socialLinks.length > 0 && (
                <MotionTypography fontSize={H6_2} fontWeight={700}>
                  {socialHandle}
                </MotionTypography>
              )}
            </MotionStack>
          )}
          {links && (
            <MotionStack
              direction={"row"}
              gap={2}
              mt={2}
              variants={iconStagger}
            >
              {socialLinks}
              {/*{links.map((item, idx) =>*/}
              {/*  item.url && item.url !== "#" ? (*/}
              {/*    <Link*/}
              {/*      key={idx}*/}
              {/*      href={item.url}*/}
              {/*      title={item.name}*/}
              {/*      target={`_${item.type}`}*/}
              {/*    >*/}
              {/*      <MotionImage*/}
              {/*        src={item.icon}*/}
              {/*        alt={item.name}*/}
              {/*        width={32}*/}
              {/*        height={32}*/}
              {/*        sx={{ width: 32, height: 32 }}*/}
              {/*        variants={iconStaggerChildren}*/}
              {/*      />*/}
              {/*    </Link>*/}
              {/*  ) : null,*/}
              {/*)}*/}
            </MotionStack>
          )}
        </MotionStack>
      )}
    </SectionWrapper>
  );
};

export default SocialSection;

const cardTransitionDown = (delay: number): MotionCardProps => {
  return {
    initial: {
      y: "-100%",
      opacity: 0,
    },
    whileInView: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
    viewport: {
      once: true,
    },
  };
};
const cardTransitionRight = (delay: number): MotionCardProps => {
  return {
    initial: {
      x: "-100%",
      opacity: 0,
    },
    whileInView: {
      x: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
    viewport: {
      once: true,
    },
  };
};

export const textTransition: MotionTypographyProps = {
  initial: {
    y: 145,
    opacity: 0,
  },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: 0.4,
    },
  },
  viewport: {
    once: true,
  },
};

export const arrowTransition: MotionBoxProps = {
  initial: {
    x: "-100%",
    opacity: 0,
  },
  whileInView: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: 0.4,
    },
  },
  viewport: {
    once: true,
  },
};
